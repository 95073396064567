import 'react-toastify/dist/ReactToastify.css';
import { default as React, FunctionComponent } from 'react';
import { ToastContainer } from 'react-toastify';
import { ApplicationContainer } from './ApplicationContainer';
import { ApplicationDetails } from './ApplicationDetails';
import { ApplicationRouter } from './ApplicationRouter';
import { CommandCreateRecipePage } from './page/command/create_recipe/CommandCreateRecipePage';
import { CommandEditRecipePage } from './page/command/edit_recipe/CommandEditRecipePage';
import { QueryGetRecipePage } from './page/query/get_recipe/QueryGetRecipePage';
import { RootPage } from './page/RootPage';

// All application routes used to render different pages.
// prettier-ignore
const ApplicationRoutes: [string, string | React.ComponentType][] = [
  ['/',                                               RootPage],
  ['/command/create-recipe',                          CommandCreateRecipePage],
  ['/command/edit-recipe',                            CommandEditRecipePage],
  ['/query/get-recipe',                               QueryGetRecipePage],
];

/*
 * Application entry point.
 *
 * Sets up the following:
 * - Root providers
 * - Router
 */
export const Application: FunctionComponent = function Application() {
  return (
    <ApplicationContainer>
      <ApplicationDetails />
      <ApplicationRouter routes={ApplicationRoutes} />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ApplicationContainer>
  );
};
