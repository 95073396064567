const RUNTIME_ENV =
  process.env.REACT_APP_RUNTIME_ENV !== 'production'
    ? 'development'
    : 'production';

// Client
let CLIENT_URL;
// TODO: Get from process.env
if (RUNTIME_ENV === 'development') {
  CLIENT_URL = 'http://localhost:4109/';
} else {
  CLIENT_URL = 'https://netprep.netlify.app/';
}

// Server
const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;
// Authorization
const AUTHORIZATION_CLIENT_ID = '149si0bm8r3nve3f2o6r02lo27';
const AUTHORIZATION_URL = 'https://netprep.auth.us-east-1.amazoncognito.com';
const AUTHORIZATION_REDIRECT_URI_LOGIN = `${API_GATEWAY_URL}/auth/login`;
const AUTHORIZATION_REDIRECT_URI_LOGOUT = `${API_GATEWAY_URL}/auth/logout`;

// Authentication
const AUTHENTICATION_URL = AUTHORIZATION_URL;
const AUTHENTICATION_URL_LOGIN = `${AUTHENTICATION_URL}/login?response_type=code&client_id=${AUTHORIZATION_CLIENT_ID}&redirect_uri=${AUTHORIZATION_REDIRECT_URI_LOGIN}`;
const AUTHENTICATION_URL_LOGOUT = `${AUTHENTICATION_URL}/logout?client_id=${AUTHORIZATION_CLIENT_ID}&logout_uri=${AUTHORIZATION_REDIRECT_URI_LOGOUT}`;

// Sentry
const SENTRY_ENABLED = process.env.REACT_APP_SENTRY_ENABLED === 'true';
const SENTRY_ENDPOINT =
  'https://99c5af3c49ec4215b098b600d34964a8@o1143037.ingest.sentry.io/6202053';

export const Config = {
  RUNTIME_ENV,

  // Client
  CLIENT_URL,

  // Server
  API_GATEWAY_URL,

  // Authentication
  AUTHENTICATION_URL,
  AUTHENTICATION_URL_LOGIN,
  AUTHENTICATION_URL_LOGOUT,

  // Sentry
  SENTRY_ENABLED,
  SENTRY_ENDPOINT,
};
