import { RecipeCourseEnum } from '@netprep/contract';
import { Fragment, FunctionComponent } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GrEdit } from 'react-icons/gr';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { useDeleteRecipe, useGetRecipe } from '../../../accessor';
import {
  Button,
  Grid,
  ScreenSize,
  Typography,
  useScreenSize,
} from '../../../component/display';
import {
  PageLayoutContent,
  PageLayoutHeader,
} from '../../../component/page_layout';
import { useAuth } from '../../../service/auth';

type Props = {
  course: RecipeCourseEnum;
  id: string;
};

export const QueryGetRecipePageLayout: FunctionComponent<Props> = ({
  course,
  id,
}) => {
  const resultAuth = useAuth();
  const { data } = useGetRecipe({ course, id });
  const [deleteRecipe] = useDeleteRecipe();

  const history = useHistory();

  const isXs = useScreenSize(ScreenSize.ExtraSmall);
  const isSm = useScreenSize(ScreenSize.Small);

  // Event Handlers
  const handleEditClick = () => {
    history.push(`/command/edit-recipe?${qs.stringify({ course, id })}`);
  };
  const handleDeleteClick = async () => {
    // Delete the recipe.
    await deleteRecipe({ course, id });

    // Navigate to home page after deletion.
    history.push('/');
  };

  return (
    <>
      <PageLayoutHeader />

      <PageLayoutContent>
        <Grid container gap={16}>
          <Grid item xs={12}>
            <Grid container gap={8}>
              <Grid item xs={12} sm={6}>
                <Grid container gap={4}>
                  <Grid item xs={12}>
                    <Typography variant="titleLg">{data.title}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">By: {data.author}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {resultAuth.authenticated && resultAuth.email === data.author && (
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: isXs ? 'flex-start' : 'flex-end',
                    }}
                  >
                    <Button variant="outline" onClick={handleEditClick}>
                      <GrEdit style={{ marginRight: 8 }} />
                      Edit
                    </Button>

                    <Button
                      variant="fill"
                      style={{
                        marginLeft: 8,
                        backgroundColor: 'var(--theme-color-error)',
                      }}
                      onClick={() => {
                        handleDeleteClick();
                      }}
                    >
                      <AiOutlineCloseCircle style={{ marginRight: 8 }} />
                      Delete
                    </Button>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container gap={16}>
              <Grid item xs={12} md={6}>
                <Grid container gap={16}>
                  <Grid item xs={12}>
                    <Typography variant="title">Ingredients</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container gap={4}>
                      {data.ingredients.split(/\r?\n/).map((line, i) => (
                        <Fragment key={i}>
                          <Grid item xs={12}>
                            <Typography variant="body">{line}</Typography>
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: 'flex',
                  justifyContent: !isSm ? 'flex-end' : 'center',
                  alignItems: 'baseline',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'baseline',
                  }}
                >
                  <img
                    src={data.images[0]}
                    width="100%"
                    height="100%"
                    style={{
                      maxWidth: '42rem',
                      maxHeight: '28rem',
                      marginBottom: 8,
                    }}
                  />

                  <Grid container>
                    <Grid item xs={6}>
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          noWrap
                          style={{ display: 'inline-block', width: 125 }}
                        >
                          Prep Time:
                        </Typography>
                        <Typography variant="caption" noWrap>
                          {data.timePrep}mins
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          noWrap
                          style={{ display: 'inline-block', width: 125 }}
                        >
                          Cook Time:
                        </Typography>
                        <Typography variant="caption" noWrap>
                          {data.timeCook}mins
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          noWrap
                          style={{ display: 'inline-block', width: 125 }}
                        >
                          Total Time:
                        </Typography>
                        <Typography variant="caption" noWrap>
                          {data.timePrep + data.timeCook}mins
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'baseline',
                          }}
                        >
                          <Typography variant="caption" noWrap>
                            Serving Size:
                          </Typography>
                          <Typography
                            variant="caption"
                            noWrap
                            style={{
                              display: 'inline-flex',
                              minWidth: 55,
                              width: 55,
                              justifyContent: 'flex-end',
                            }}
                          >
                            {data.servingSize}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'baseline',
                          }}
                        >
                          <Typography variant="caption" noWrap>
                            Calories:
                          </Typography>
                          <Typography
                            variant="caption"
                            noWrap
                            style={{
                              display: 'inline-flex',
                              minWidth: 55,
                              width: 55,
                              justifyContent: 'flex-end',
                            }}
                          >
                            {data.calories}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>

          {/* Directions */}
          <Grid item xs={12}>
            <Grid container gap={16}>
              <Grid item xs={12}>
                <Typography variant="title">Directions</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container gap={8}>
                  {data.directions.split(/\r?\n/).map((line, i) => (
                    <Fragment key={i}>
                      <Grid item xs={12}>
                        <Typography variant="body">{line}</Typography>
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageLayoutContent>
    </>
  );
};
