import * as yup from 'yup';
import { RecipeSchema } from '../data';

export const CreateRecipeResponseSchema = yup.object({
  recipe: RecipeSchema,
});

export type CreateRecipeResponse = yup.InferType<
  typeof CreateRecipeResponseSchema
>;
