import { createLogger } from '@null-studios/logger';
import {
  ClipboardEvent,
  CSSProperties,
  FunctionComponent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { v4 as uuid4 } from 'uuid';
import { Typography } from '../../component/display';
import classes from './FormRecipePageDropZone.module.css';

const getBase64FromUrl = async (url: string): Promise<string> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data as string);
    };
  });
};

const logger = createLogger(__filename);

const MAX_FILE_UPLOAD = 1;
const MAX_FILE_SIZE = 3000000;

type FillDirection = 'width' | 'height';

type Props = {
  autoFocus?: boolean;
  isError?: boolean;
  errorText?: string;
  isDisabled: boolean;
  accept: string;
  fillDirection: FillDirection;
  files: null | string[];
  fileSelectedIndex: number;
  onChangeSelectedIndex?: (index: number) => void;
  onChange?: (files: string[]) => void;
  style?: CSSProperties;
};

// TODO: autoFocus
export const FormRecipePageDropzone: FunctionComponent<Props> = ({
  autoFocus,
  isError,
  errorText,
  isDisabled,
  accept,
  fillDirection,
  files,
  fileSelectedIndex,
  onChangeSelectedIndex,
  onChange,
  style,
}) => {
  const isMultiple = MAX_FILE_UPLOAD > 1;

  const [internalFiles, setInternalFiles] = useState<null | string[]>(files);
  useEffect(() => {
    setInternalFiles(files);
  }, [files]);

  const getLimitedFiles = (
    existingFiles: string[],
    acceptedFiles: File[],
  ): File[] => {
    logger.info(`Limiting files accepted to ${MAX_FILE_UPLOAD}`);
    const newFiles = acceptedFiles.slice(0);
    if (existingFiles.length + newFiles.length > MAX_FILE_UPLOAD) {
      newFiles.splice(MAX_FILE_UPLOAD - existingFiles.length);
      toast(
        <>
          <Typography variant="body">
            Only {MAX_FILE_UPLOAD} images are supported.
          </Typography>
          <br />
          <Typography variant="body">
            Using the first {MAX_FILE_UPLOAD} uploaded.
          </Typography>
        </>,
      );
    }
    return newFiles;
  };

  const getMaxFiles = async (acceptedFiles: File[]): Promise<File[]> => {
    logger.info(`Limiting file sizes to ${MAX_FILE_SIZE}`);
    const newFileTransformers: Promise<null | File>[] = [];
    for (const file of acceptedFiles) {
      if (file.size > MAX_FILE_SIZE) {
        // Try client side compression.
        newFileTransformers.push(
          new Promise((resolve, reject) => {
            logger.info(`Attempting compression...`);
            logger.info(`Original Size: ${file.size}`);
            const blobUrl = window.URL.createObjectURL(file);
            const img = new Image();
            img.src = blobUrl;
            img.onload = function () {
              // Release memory.
              window.URL.revokeObjectURL(blobUrl);
              // Create canvas for compression.
              const newWidth = img.naturalWidth / 2;
              const newHeight = img.naturalHeight / 2;
              const canvas = document.createElement('canvas');
              canvas.width = newWidth;
              canvas.height = newHeight;
              const ctx = canvas.getContext('2d');
              if (!ctx) {
                return resolve(null);
              }
              // Draw to canvas.
              ctx.drawImage(img, 0, 0, newWidth, newHeight);
              // Compress image.
              canvas.toBlob(
                function (blob) {
                  if (blob === null) {
                    reject(new Error('Blob is null.'));
                  } else {
                    logger.info(`New Size: ${blob.size}`);
                    resolve(
                      new File([blob], `${uuid4()}.jpg`, {
                        type: 'image/jpeg',
                      }),
                    );
                  }
                },
                'image/jpeg',
                50,
              );
            };
          }),
        );
      } else {
        newFileTransformers.push(Promise.resolve(file));
      }
    }

    let showMaxFileSizeToast;
    const newFiles: File[] = [];
    const filesTransformed = await Promise.all(newFileTransformers);
    for (const file of filesTransformed) {
      if (!file) continue;
      if (file.size > MAX_FILE_SIZE) {
        showMaxFileSizeToast = true;
      } else {
        newFiles.push(file);
      }
    }
    if (showMaxFileSizeToast) {
      toast(
        <>
          <Typography variant="body">
            Images can only be up to {(MAX_FILE_SIZE / 1000000).toFixed(1)}
            mb large.
          </Typography>
        </>,
      );
    }

    return newFiles;
  };

  // TODO: Should indicate to the user that an upload is happening.
  //       Need to determine how fast a compression can occur.
  const updateInternalFiles = async (acceptedFiles: File[]) => {
    const existingFiles = internalFiles?.slice(0) || [];
    const newFilesLimit = getLimitedFiles(existingFiles, acceptedFiles);
    const newFilesMax = await getMaxFiles(newFilesLimit);
    const newFileBlobUrls = newFilesMax.map(f => URL.createObjectURL(f));
    const newFiles = await Promise.all(
      newFileBlobUrls.map(f => getBase64FromUrl(f)),
    );

    setInternalFiles([...existingFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps, isFocused } = useDropzone({
    accept,
    multiple: isMultiple,
    disabled: isDisabled,
    /*
     * The following properties need to be implemented bc there's no
     *  feedback from invalid files.
     *
     * maxFiles: 1,
     * maxSize: 3000000, // 3mb
     */
    onDrop: acceptedFiles => {
      // Update.
      updateInternalFiles(acceptedFiles);
    },
  });

  const clearSelectedImage = () => {
    setInternalFiles(internalFiles => {
      if (!internalFiles) return internalFiles;
      if (internalFiles.length <= 0) return internalFiles;

      const newInternalFiles = internalFiles.slice(0);
      const selectedImage = newInternalFiles.splice(fileSelectedIndex, 1);
      URL.revokeObjectURL(selectedImage[0]);

      if (fileSelectedIndex >= newInternalFiles.length) {
        let newIndex = newInternalFiles.length - 1;
        if (newIndex < 0) newIndex = 0;
        onChangeSelectedIndex && onChangeSelectedIndex(newIndex);
      }

      return newInternalFiles;
    });
  };

  // Event Handlers
  const handleKeyDown = async (e: KeyboardEvent<HTMLDivElement>) => {
    // Allow accessing drop zone with space or enter keys.
    if (e.key === ' ' || e.key === 'Enter') {
      e.currentTarget.click();
    }

    // Allow ability to paste images to drop zone.
    if (e.ctrlKey && e.key === 'v') {
      const imageFiles = [];
      const items = await window.navigator.clipboard.read();
      for (const index in items) {
        const item = items[index];
        if (!item.types.includes('image/png')) continue;
        const imageBlob = await item.getType('image/png');
        const imageFile = new File([imageBlob], `${uuid4()}.png`, {
          type: 'image/png',
        });
        imageFiles.push(imageFile);
      }

      // Update.
      updateInternalFiles(imageFiles);
    }
  };
  const handleClear = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    clearSelectedImage();
  };
  const handleSetPreviewImageClick = (
    e: MouseEvent<HTMLDivElement>,
    i: number,
  ) => {
    e.stopPropagation();
    onChangeSelectedIndex && onChangeSelectedIndex(i);
  };
  const handleSetPreviewImageKeyDown = (
    e: KeyboardEvent<HTMLDivElement>,
    i: number,
  ) => {
    if (e.key === 'c') {
      e.preventDefault();
      clearSelectedImage();
    }

    if (e.key === ' ' || e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      onChangeSelectedIndex && onChangeSelectedIndex(i);
    }
  };

  const refIsMounted = useRef<boolean>(false);
  useEffect(() => {
    if (!refIsMounted.current) {
      refIsMounted.current = true;
      return;
    }

    if (internalFiles) {
      // Notify parent of file change.
      onChange && onChange(internalFiles);
    }
  }, [internalFiles]);

  return (
    <div
      {...getRootProps({
        className: `${classes.dropZone} ${
          !isFocused ? classes.dropZoneNoFocus : classes.dropZoneFocus
        } ${isError ? classes.dropZoneError : ''} ${
          !isDisabled ? '' : classes.dropZoneDisabled
        }`,
        style: { ...style },
      })}
      onKeyDown={handleKeyDown}
    >
      <input {...getInputProps({})} />
      {!internalFiles || internalFiles.length <= 0 ? (
        <>
          <p style={{ textAlign: 'center' }}>
            {isError ? (
              <>
                {errorText}
                <br />
              </>
            ) : null}
            Click here to add images. <br />
            You can also drag 'n drop images here.
          </p>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              ...(fillDirection === 'height'
                ? {
                    flexDirection: 'column',
                    alignItems: 'center',
                  }
                : {
                    flexDirection: 'row',
                    alignItems: 'normal',
                  }),
              width: '100%',
              height: '75%',
            }}
          >
            {/* Close Icon */}
            <div
              style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
              onClick={handleClear}
            >
              <Typography variant="body" className={classes.iconClose}>
                <AiOutlineCloseCircle style={{ fontSize: 'inherit' }} />
              </Typography>
            </div>

            {/* Image Preview */}
            <img
              style={{
                padding: '1.25rem',
                ...(fillDirection === 'height'
                  ? { height: '100%', maxWidth: '100%' }
                  : { width: '100%', maxHeight: '100%' }),
              }}
              src={internalFiles[fileSelectedIndex]}
              key={internalFiles[fileSelectedIndex]}
            />
          </div>

          {isMultiple && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                height: '25%',
                overflowX: 'auto',
                overflowY: 'hidden',
                paddingRight: '1.5rem',
              }}
              onClick={e => e.stopPropagation()}
            >
              {/* Image Previews */}
              {internalFiles.map((internalFile, i) => (
                <div key={internalFile} style={{ marginRight: 8 }}>
                  <img
                    tabIndex={0}
                    style={{
                      width: 50,
                      height: 50,
                    }}
                    className={`${classes.imagePreview} ${
                      i === fileSelectedIndex
                        ? classes.imagePreviewSelected
                        : ''
                    }`}
                    src={internalFile}
                    onClick={e => handleSetPreviewImageClick(e, i)}
                    onKeyDown={e => handleSetPreviewImageKeyDown(e, i)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
