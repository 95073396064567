import { PaginationInfo, Recipe, RecipeCourseEnum } from '@netprep/contract';
import { State } from './store';

export const getRequest =
  (key: string) =>
  (state: State): boolean =>
    !!state.request.get(key);

export const getPaginationInfo =
  (key: string) =>
  (state: State): undefined | PaginationInfo =>
    state.pagination.get(key);

export const getRecipeById =
  (id: string) =>
  (state: State): null | Recipe => {
    const value = state.recipe.get(id);
    return value ? value : null;
  };

export const getRecipeByCourse =
  (course: RecipeCourseEnum) =>
  (state: State): Recipe[] => {
    const values = [];
    for (const value of state.recipe.values()) {
      if (value.course !== course) continue;
      values.push(value);
    }
    return values;
  };
