import type {
  CSSProperties,
  FunctionComponent,
  MouseEventHandler,
  ReactNode,
} from 'react';
import classes from './Button.module.css';

type TypeVariant = 'button' | 'submit' | 'reset';
type Variant = 'fill' | 'outline';

const classVariant: { [Property in Variant]: string } = {
  fill: classes.fill,
  outline: classes.outline,
};

type Props = {
  type?: TypeVariant;
  variant?: Variant;
  isDisabled?: boolean;
  style?: CSSProperties;
  onClick?: MouseEventHandler<Element>;
  children: ReactNode;
};

export const Button: FunctionComponent<Props> = ({
  type,
  variant,
  isDisabled,
  style,
  children,
  onClick,
}) => {
  const pVariant = typeof variant === 'undefined' ? 'fill' : variant;

  return (
    <button
      className={`${classes.button} ${classVariant[pVariant]}`}
      style={style}
      disabled={isDisabled}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
