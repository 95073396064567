import { FunctionComponent, ReactNode, Suspense } from 'react';
import { ScreenSize, useScreenSizeValue } from '../display';
import classes from './PageLayout.module.css';

const classScreenSize: { [Property in ScreenSize]: string } = {
  [ScreenSize.ExtraSmall]: classes.screenSizeXs,
  [ScreenSize.Small]: classes.screenSizeSm,
  [ScreenSize.Medium]: classes.screenSizeMd,
  [ScreenSize.Large]: classes.screenSizeLg,
  [ScreenSize.ExtraLarge]: classes.screenSizeXl,
};

type Props = {
  children: ReactNode;
};

export const PageLayout: FunctionComponent<Props> = ({ children }) => {
  const screenSize = useScreenSizeValue();

  return (
    <Suspense fallback={null}>
      <div className={`${classes.content} ${classScreenSize[screenSize]}`}>
        {children}
      </div>
    </Suspense>
  );
};
