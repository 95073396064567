import { AuthUserInfoResponse } from '@netprep/contract';
import { setAccessToken, store } from './service';

const storeCacheKey = 'getUserInfo';

/*
 * Authorization workflow.
 */
export const useAuth = (config?: {
  access_token?: string;
}): AuthUserInfoResponse => {
  // 0. Inputs.
  const { access_token } = config || {};

  // 1. Are we saving the authorization access token?
  if (access_token) {
    setAccessToken(access_token);
  }

  // 2. Try to get user info with the saved access token.
  return store.getUserInfo.get(`${storeCacheKey}_${access_token}`);
};
