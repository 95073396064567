import { RecipeCourseEnum } from '@netprep/contract';
import { createLogger } from '@null-studios/logger';
import { values } from 'ramda';
import {
  ClipboardEventHandler,
  CSSProperties,
  FunctionComponent,
  KeyboardEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Grid,
  InputLabel,
  InputNumber,
  InputSelect,
  InputText,
  InputTextArea,
  ScreenSize,
  Typography,
  useScreenSizeValue,
} from '../../component/display';
import { FormRecipePageDropzone } from './FormRecipePageDropzone';

const logger = createLogger(__filename);

const styleDropZone: { [Property in ScreenSize]: CSSProperties } = {
  [ScreenSize.ExtraSmall]: { height: '20rem' },
  [ScreenSize.Small]: { height: '20rem' },
  [ScreenSize.Medium]: { height: '20rem' },
  [ScreenSize.Large]: { height: '22rem' },
  [ScreenSize.ExtraLarge]: { height: '25rem' },
};

const regexLineIsOnlyStep = /^step\s+\d$/gi;
const regexLineStep = /^(?:\s*(\d+)(?:\.|\)\s*))?(.+)/gm;
const formatDirections = (contents: string) => {
  const lines = contents.split('\n');

  const aggregate = [];
  let step = 1;
  for (const line of lines) {
    if (regexLineIsOnlyStep.test(line)) {
      continue;
    }
    if (line === '') {
      continue;
    }
    const contentStep = regexLineStep.exec(line);
    regexLineStep.lastIndex = 0;
    if (!contentStep) {
      continue;
    }
    aggregate.push(`${step}. ${contentStep[2]}`);
    step += 1;
  }
  return aggregate.join('\n\n');
};

export const formDataDefault = {
  images: [],
  imageSelectedIndex: 0,
  title: '',
  course: null,
  ingredients: '',
  directions: '',
  calories: 0,
  servingSize: 0,
  timePrep: 0,
  timeCook: 0,
};

export type FormData = {
  images: string[];
  imageSelectedIndex: number;
  title: string;
  course: null | { value: RecipeCourseEnum; label: string };
  ingredients: string;
  directions: string;
  calories: number;
  servingSize: number;
  timePrep: number;
  timeCook: number;
};

export type FormDataSubmitted = {
  title: string;
  course: RecipeCourseEnum;
  images: string[];
  ingredients: string;
  directions: string;
  timePrep: number;
  timeCook: number;
  calories: number;
  servingSize: number;
};

type Props = {
  title: string;
  submitButtonText: string;
  formData: FormData;
  onFormDataChange: (v: FormData | ((vs: FormData) => FormData)) => void;
  onSubmit: (v: FormDataSubmitted) => Promise<void>;
};

export const FormRecipeLayout: FunctionComponent<Props> = ({
  title,
  submitButtonText,
  formData,
  onFormDataChange,
  onSubmit,
}) => {
  const screenSize = useScreenSizeValue();
  const refStartOfContent = useRef<null | HTMLDivElement>(null);
  const refEndOfContent = useRef<null | HTMLDivElement>(null);

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  // Form Validation
  const isValidTitleRequired = formData.title.length > 0;
  const isValidCourseRequired = formData.course !== null;
  const isValidImagesRequired = formData.images.length > 0;
  const isValidIngredientsRequired = formData.ingredients.length > 0;
  const isValidDirectionsRequired = formData.directions.length > 0;
  const isValidCaloriesNonNegative = formData.calories >= 0;
  const isValidServingSizeNonNegative = formData.servingSize >= 0;
  const isValidTimePrepNonNegative = formData.timePrep >= 0;
  const isValidTimeCookNonNegative = formData.timeCook >= 0;

  // Event Handlers
  const handleFormImagePreviewChange = (files: string[]) => {
    onFormDataChange(v => {
      return {
        ...v,
        images: files,
      };
    });
  };
  const handlePasteDirections: ClipboardEventHandler<
    HTMLTextAreaElement
  > = e => {
    logger.info('Pasted recipe directions.');

    const contents = e.clipboardData.getData('Text');
    logger.json({ contents });

    // Are we pasting a recipe or just pasting?
    if (
      e.currentTarget.value.length !== 0 &&
      window.getSelection()?.toString().length !== e.currentTarget.value.length
    ) {
      return;
    }
    // Prevent paste from writting to input.
    e.preventDefault();

    // Format directions content for consistency.
    const directionsFormatted = formatDirections(contents);

    if (document.execCommand) {
      // Used to support undo/redo functionality for the user.
      document.execCommand('insertText', false, directionsFormatted);
    }

    // Scroll to the bottom if content extends past new content.
    setTimeout(() => {
      if (!refEndOfContent.current) {
        return;
      }
      refEndOfContent.current.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };
  const handleDirectionsChange = useCallback(
    (contents: string) => {
      // Append next step if the user added new lines.
      if (contents.length >= formData.directions.length) {
        let match;
        let step = 0;
        while ((match = regexLineStep.exec(contents))) {
          step = Number(match[1]);
        }
        if (contents.endsWith('\n\n')) {
          contents += `${step + 1}. `;
        }
      }

      onFormDataChange(v => {
        return {
          ...v,
          directions: contents,
        };
      });
    },
    [formData.directions],
  );
  const handleFormTextAreaKeyDown: KeyboardEventHandler<
    HTMLTextAreaElement
  > = e => {
    if (e.ctrlKey && e.key === 'Enter') {
      handleSubmit();
    }
  };
  const handleSubmit = async () => {
    setIsFormSubmitted(true);
    logger.info('Submitting form.');

    if (
      !isValidTitleRequired ||
      !isValidCourseRequired ||
      !isValidImagesRequired ||
      !isValidIngredientsRequired ||
      !isValidDirectionsRequired ||
      !isValidCaloriesNonNegative ||
      !isValidServingSizeNonNegative ||
      !isValidTimePrepNonNegative ||
      !isValidTimeCookNonNegative ||
      !formData.course
    ) {
      logger.info('Failed form validation.');
      refStartOfContent.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    // Set the preview image to the first slot.
    // Explicitly uses this as the preview image.
    let newFormImages = formData.images.slice(0);
    const setImage = newFormImages.splice(formData.imageSelectedIndex, 1);
    newFormImages = [...setImage, ...newFormImages];

    // Format directions content for consistency.
    const directionsFormatted = formatDirections(formData.directions);

    // Indicate to the user the form is being submitted.
    setIsFormSubmitting(true);

    await onSubmit({
      title: formData.title,
      course: formData.course.value,
      images: newFormImages,
      ingredients: formData.ingredients,
      directions: directionsFormatted,
      timePrep: formData.timePrep,
      timeCook: formData.timeCook,
      calories: formData.calories,
      servingSize: formData.servingSize,
    });

    // Reset form submitting.
    setIsFormSubmitting(false);
  };

  return (
    <>
      <div ref={refStartOfContent} />

      <Grid container gap={8}>
        <Grid item xs={12}>
          <Typography variant="titleLg">{title}</Typography>
        </Grid>

        <Grid item xs={12}>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid container gap={8}>
              {screenSize === ScreenSize.Medium ||
              screenSize === ScreenSize.Large ||
              screenSize === ScreenSize.ExtraLarge ? (
                <Grid item xs={12}>
                  <Grid container gap={8}>
                    <Grid item xs={4}>
                      <FormRecipePageDropzone
                        autoFocus
                        isError={isFormSubmitted && !isValidImagesRequired}
                        errorText={'An image is required.'}
                        isDisabled={isFormSubmitting}
                        files={formData.images}
                        fileSelectedIndex={formData.imageSelectedIndex}
                        onChangeSelectedIndex={i => {
                          onFormDataChange(v => {
                            return {
                              ...v,
                              imageSelectedIndex: i,
                            };
                          });
                        }}
                        onChange={handleFormImagePreviewChange}
                        accept="image/*"
                        fillDirection="width"
                        style={styleDropZone[screenSize]}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        <Grid container gap={8}>
                          <Grid item xs={6}>
                            <InputTitle
                              isError={isFormSubmitted && !isValidTitleRequired}
                              isDisabled={isFormSubmitting}
                              formTitle={formData.title}
                              setFormTitle={title => {
                                onFormDataChange(v => {
                                  return {
                                    ...v,
                                    title,
                                  };
                                });
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputCourse
                              isError={
                                isFormSubmitted && !isValidCourseRequired
                              }
                              isDisabled={isFormSubmitting}
                              formCourse={formData.course}
                              setFormCourse={course => {
                                onFormDataChange(v => {
                                  return {
                                    ...v,
                                    course,
                                  };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container gap={8}>
                          <Grid item xs={6}>
                            <InputCalories
                              isError={
                                isFormSubmitted && !isValidCaloriesNonNegative
                              }
                              isDisabled={isFormSubmitting}
                              formCalories={formData.calories}
                              setFormCalories={calories => {
                                onFormDataChange(v => {
                                  return {
                                    ...v,
                                    calories,
                                  };
                                });
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputServingSize
                              isError={
                                isFormSubmitted &&
                                !isValidServingSizeNonNegative
                              }
                              isDisabled={isFormSubmitting}
                              formServingSize={formData.servingSize}
                              setFormServingSize={servingSize => {
                                onFormDataChange(v => {
                                  return {
                                    ...v,
                                    servingSize,
                                  };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>

                        <InputLabel
                          label="Ingredients"
                          isRequired
                          isError={
                            isFormSubmitted && !isValidIngredientsRequired
                          }
                          style={{ flex: '1 0 auto' }}
                        >
                          <InputTextArea
                            style={{ flex: '1 0 auto' }}
                            styleInput={{ resize: 'vertical' }}
                            value={formData.ingredients}
                            placeholder="Ingredients"
                            isError={
                              isFormSubmitted && !isValidIngredientsRequired
                            }
                            isDisabled={isFormSubmitting}
                            onKeyDown={handleFormTextAreaKeyDown}
                            onChange={e => {
                              onFormDataChange(v => {
                                return {
                                  ...v,
                                  ingredients: e.target.value,
                                };
                              });
                            }}
                          />
                        </InputLabel>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Grid container gap={8}>
                      <Grid item xs={12} sm={6}>
                        <InputTitle
                          isError={isFormSubmitted && !isValidTitleRequired}
                          isDisabled={isFormSubmitting}
                          formTitle={formData.title}
                          setFormTitle={title => {
                            onFormDataChange(v => {
                              return {
                                ...v,
                                title,
                              };
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <InputCourse
                          isError={isFormSubmitted && !isValidCourseRequired}
                          isDisabled={isFormSubmitting}
                          formCourse={formData.course}
                          setFormCourse={course => {
                            onFormDataChange(v => {
                              return {
                                ...v,
                                course,
                              };
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <FormRecipePageDropzone
                      autoFocus
                      isError={isFormSubmitted && !isValidImagesRequired}
                      errorText={'An image is required.'}
                      isDisabled={isFormSubmitting}
                      files={formData.images}
                      fileSelectedIndex={formData.imageSelectedIndex}
                      onChangeSelectedIndex={i => {
                        onFormDataChange(v => {
                          return {
                            ...v,
                            imageSelectedIndex: i,
                          };
                        });
                      }}
                      onChange={handleFormImagePreviewChange}
                      accept="image/*"
                      fillDirection="height"
                      style={styleDropZone[screenSize]}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container gap={8}>
                      <Grid item xs={6}>
                        <InputCalories
                          isError={
                            isFormSubmitted && !isValidCaloriesNonNegative
                          }
                          isDisabled={isFormSubmitting}
                          formCalories={formData.calories}
                          setFormCalories={calories => {
                            onFormDataChange(v => {
                              return {
                                ...v,
                                calories,
                              };
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <InputServingSize
                          isError={
                            isFormSubmitted && !isValidServingSizeNonNegative
                          }
                          isDisabled={isFormSubmitting}
                          formServingSize={formData.servingSize}
                          setFormServingSize={servingSize => {
                            onFormDataChange(v => {
                              return {
                                ...v,
                                servingSize,
                              };
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel
                      label="Ingredients"
                      isRequired
                      isError={isFormSubmitted && !isValidIngredientsRequired}
                    >
                      <InputTextArea
                        styleInput={{ resize: 'vertical' }}
                        autoGrow
                        rows={10}
                        value={formData.ingredients}
                        placeholder="Ingredients"
                        isError={isFormSubmitted && !isValidIngredientsRequired}
                        isDisabled={isFormSubmitting}
                        onKeyDown={handleFormTextAreaKeyDown}
                        onChange={e => {
                          onFormDataChange(v => {
                            return {
                              ...v,
                              ingredients: e.target.value,
                            };
                          });
                        }}
                      />
                    </InputLabel>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <InputLabel
                  label="Directions"
                  isRequired
                  isError={isFormSubmitted && !isValidDirectionsRequired}
                >
                  <InputTextArea
                    autoGrow
                    rows={10}
                    value={formData.directions}
                    placeholder="Directions"
                    isError={isFormSubmitted && !isValidDirectionsRequired}
                    isDisabled={isFormSubmitting}
                    onChange={e => handleDirectionsChange(e.target.value)}
                    onPaste={handlePasteDirections}
                    onKeyDown={handleFormTextAreaKeyDown}
                  />
                </InputLabel>
              </Grid>

              <Grid item xs={12}>
                <Grid container gap={8}>
                  <Grid item xs={6} sm={4}>
                    <InputLabel
                      label="Prep Time (mins)"
                      isRequired
                      isError={isFormSubmitted && !isValidTimePrepNonNegative}
                    >
                      <InputNumber
                        value={formData.timePrep}
                        isError={isFormSubmitted && !isValidTimePrepNonNegative}
                        isDisabled={isFormSubmitting}
                        onFocus={e => e.currentTarget.select()}
                        onChange={e =>
                          onFormDataChange(v => {
                            return {
                              ...v,
                              timePrep: Number(e.target.value),
                            };
                          })
                        }
                      />
                    </InputLabel>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <InputLabel
                      label="Cook Time (mins)"
                      isRequired
                      isError={isFormSubmitted && !isValidTimeCookNonNegative}
                    >
                      <InputNumber
                        value={formData.timeCook}
                        isError={isFormSubmitted && !isValidTimeCookNonNegative}
                        isDisabled={isFormSubmitting}
                        onFocus={e => e.currentTarget.select()}
                        onChange={e =>
                          onFormDataChange(v => {
                            return {
                              ...v,
                              timeCook: Number(e.target.value),
                            };
                          })
                        }
                      />
                    </InputLabel>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <InputLabel label="Total Time (mins)">
                      <InputNumber
                        value={formData.timePrep + formData.timeCook}
                        isDisabled
                      />
                    </InputLabel>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="submit" isDisabled={isFormSubmitting}>
                    {submitButtonText}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <div ref={refEndOfContent} />
    </>
  );
};

const InputTitle: FunctionComponent<{
  isError: boolean;
  isDisabled: boolean;
  formTitle: string;
  setFormTitle: (v: string) => void;
}> = ({ isError, isDisabled, formTitle, setFormTitle }) => {
  return (
    <InputLabel label="Title" isRequired isError={isError}>
      <InputText
        placeholder="Title"
        value={formTitle}
        isError={isError}
        isDisabled={isDisabled}
        onChange={e => setFormTitle(e.target.value)}
      />
    </InputLabel>
  );
};

const InputCourse: FunctionComponent<{
  isError: boolean;
  isDisabled: boolean;
  formCourse: null | { value: RecipeCourseEnum; label: string };
  setFormCourse: (v: null | { value: RecipeCourseEnum; label: string }) => void;
}> = ({ isError, isDisabled, formCourse, setFormCourse }) => {
  return (
    <InputLabel label="Course" isRequired isError={isError}>
      <InputSelect
        placeholder="Select Course..."
        value={formCourse}
        options={values(RecipeCourseEnum).map(course => ({
          value: course,
          label: course,
        }))}
        isError={isError}
        isDisabled={isDisabled}
        onChange={v => {
          setFormCourse(v as { value: RecipeCourseEnum; label: string });
        }}
      />
    </InputLabel>
  );
};

const InputCalories: FunctionComponent<{
  isError: boolean;
  isDisabled: boolean;
  formCalories: number;
  setFormCalories: (v: number) => void;
}> = ({ isError, isDisabled, formCalories, setFormCalories }) => {
  return (
    <InputLabel label="Calories" isRequired isError={isError}>
      <InputNumber
        value={formCalories}
        isError={isError}
        isDisabled={isDisabled}
        onChange={e => setFormCalories(Number(e.target.value))}
        onFocus={e => e.currentTarget.select()}
      />
    </InputLabel>
  );
};

const InputServingSize: FunctionComponent<{
  isError: boolean;
  isDisabled: boolean;
  formServingSize: number;
  setFormServingSize: (v: number) => void;
}> = ({ isError, isDisabled, formServingSize, setFormServingSize }) => {
  return (
    <InputLabel
      label="Serving Size"
      isRequired
      isError={isError}
      style={{ marginBottom: 8 }}
    >
      <InputNumber
        value={formServingSize}
        isError={isError}
        isDisabled={isDisabled}
        onChange={e => setFormServingSize(Number(e.target.value))}
        onFocus={e => e.currentTarget.select()}
      />
    </InputLabel>
  );
};
