import { createLogger } from '@null-studios/logger';
import { enableMapSet } from 'immer';
import { render } from 'react-dom';
import React from 'react';
import './index.global.css';
import { Application } from './Application';
import { initializeSentry } from './sentry';

const logger = createLogger(__filename);

const main = () => {
  const rootElement = document.getElementById('root') as void | HTMLDivElement;
  if (!rootElement) {
    logger.error(`Unable to find 'root' element.`);
    return;
  }

  initializeSentry();

  enableMapSet();

  render(
    <React.StrictMode>
      <Application />
    </React.StrictMode>,
    rootElement,
  );
};

document.addEventListener('DOMContentLoaded', main);
