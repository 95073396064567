import { AuthUserInfoResponse } from '@netprep/contract';
import { createFetchStore } from 'react-suspense-fetch';
import { Config } from '../../config';

/*
 * Get/Set authorization.
 */
let _accessToken: string | null = null;
export const clearAccessToken = (): void => {
  _accessToken = null;
  localStorage.setItem('access_token', '');
};
export const setAccessToken = (access_token: string): void => {
  _accessToken = access_token;
  localStorage.setItem('access_token', access_token);
};
export const getAccessToken = (): string | null => {
  if (!_accessToken) {
    _accessToken = localStorage.getItem('access_token');
  }
  return _accessToken;
};
export const getAuthorization = (): string => {
  return `Bearer ${getAccessToken()}`;
};

/*
 * Get user info.
 */
export const getUserInfo = async (): Promise<AuthUserInfoResponse> => {
  const response = await fetch(`${Config.API_GATEWAY_URL}/auth/userinfo`, {
    headers: {
      // NOTE: Authorization is not used here. See backend for more info.
      Authorize: getAuthorization(),
    },
  });

  return await response.json();
};

class UnauthorizedError extends Error {
  constructor() {
    super('You are not authorized.');
  }
}

/*
 * Validate response is authorized.
 */
export const validateAuthorizedResponse = (response: Response): void => {
  if (response.status === 401) {
    throw new UnauthorizedError();
  }
};

/*
 * Cache store for the service.
 */
export const store = {
  getUserInfo: createFetchStore(getUserInfo),
};
