import { FunctionComponent } from 'react';
import qs from 'query-string';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutHeader,
} from '../component/page_layout';
import { useAuth } from '../service/auth';
import { RootPageLayout } from './RootPageLayout';

/*
 * Root landing page for end users.
 *
 * User also lands on this page when finishing authentication.
 */
export const RootPage: FunctionComponent = () => {
  return (
    <PageLayout>
      <Authentication>
        <PageLayoutHeader />

        <PageLayoutContent>
          <RootPageLayout />
        </PageLayoutContent>
      </Authentication>
    </PageLayout>
  );
};

const Authentication: FunctionComponent = ({ children }) => {
  useAuth({
    // Authorization header is setup here when user finishes authentication.
    access_token: (() => {
      const search = qs.parse(window.location.search);
      return typeof search.access_token === 'string' ? search.access_token : '';
    })(),
  });

  return <>{children}</>;
};
