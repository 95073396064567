import { RecipeCourseEnum } from '@netprep/contract';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import qs from 'query-string';
import { useEditRecipe, useGetRecipe } from '../../../accessor';
import { Typography } from '../../../component/display';
import {
  PageLayoutContent,
  PageLayoutHeader,
} from '../../../component/page_layout';
import { useLocalStorage } from '../../../hook';
import {
  FormData,
  formDataDefault,
  FormDataSubmitted,
  FormRecipeLayout,
} from '../../../layout/form_recipe';
import { captureException } from '../../../sentry';

const formLocalStorage = `CommandEditRecipePage`;
const formLocalStorageId = `CommandEditRecipePage.id`;
const formLocalStorageLastChangeDate = `CommandEditRecipePage.lastChangeDate`;

type Props = {
  course: RecipeCourseEnum;
  id: string;
};

export const CommandEditRecipePageLayout: FunctionComponent<Props> = ({
  course,
  id,
}) => {
  const history = useHistory();

  const { data } = useGetRecipe({ course, id });
  const [editRecipe] = useEditRecipe();

  const [formData, setFormData] = useLocalStorage<FormData>(
    formLocalStorage,
    formDataDefault,
  );
  const [formLastId, setFormLastId] = useLocalStorage<null | string>(
    formLocalStorageId,
    null,
  );
  const [formLastChangeDate, setFormLastChangeDate] = useLocalStorage<
    null | number
  >(formLocalStorageLastChangeDate, null);

  useEffect(() => {
    const nowMilliseconds = Date.now();
    // Has the form been changed? If last change date is less than 3 hours reload data.
    if (
      formLastId !== id ||
      !formLastChangeDate ||
      nowMilliseconds - formLastChangeDate > 1 * 1000 * 60 * 60 * 3
    ) {
      // Set local storage to loaded recipe.
      setFormData({
        images: data.images,
        imageSelectedIndex: 0,
        title: data.title,
        course: { value: data.course, label: data.course },
        ingredients: data.ingredients,
        directions: data.directions,
        calories: data.calories,
        servingSize: data.servingSize,
        timePrep: data.timePrep,
        timeCook: data.timeCook,
      });
      setFormLastId(id);
      setFormLastChangeDate(nowMilliseconds);
    }
  }, [formLastId, formLastChangeDate, data]);

  // Event Handlers
  const handleFormDataChange = (v: FormData | ((v: FormData) => FormData)) => {
    setFormData(v);
    setFormLastChangeDate(Date.now());
  };
  const handleSubmit = useCallback(
    async (formDataSubmitted: FormDataSubmitted) => {
      try {
        // Edit the recipe.
        const result = await editRecipe({
          oldRecipe: {
            course,
            id,
          },
          newRecipe: formDataSubmitted,
        });

        // Reset local storage since recipe is now stored in BE.
        setFormData(formDataDefault);
        setFormLastChangeDate(null);

        // Navigate user to edited recipe.
        history.push(
          `/query/get-recipe?${qs.stringify({
            course: result.recipe.course,
            id: result.recipe.id,
          })}`,
        );
      } catch (e) {
        captureException(e);
        const error = e as undefined | Error;
        toast(
          <>
            <Typography variant="body">
              Unable to edit your recipe at this time.
            </Typography>
            <br />
            <Typography variant="body">
              {error?.message || 'Unkown error occurred.'}
            </Typography>
            <br />
            <Typography variant="body">Please try again later.</Typography>
          </>,
        );
      }
    },
    [course, id],
  );

  return (
    <>
      <PageLayoutHeader />

      <PageLayoutContent>
        <FormRecipeLayout
          title="Edit Recipe"
          submitButtonText="Save"
          formData={formData}
          onFormDataChange={handleFormDataChange}
          onSubmit={handleSubmit}
        />
      </PageLayoutContent>
    </>
  );
};
