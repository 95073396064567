import { createLogger } from '@null-studios/logger';
import { FunctionComponent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../component/display';
import { Config } from '../../config';
import { clearAccessToken, useAuth } from '../../service/auth';
import { Typography } from '../display';
import classes from './PageLayoutHeader.module.css';

const logger = createLogger(__filename);

export const PageLayoutHeader: FunctionComponent = () => {
  const history = useHistory();

  const resultAuth = useAuth();

  // Event Handlers
  const handleCreateRecipeClick = () => {
    history.push('/command/create-recipe');
  };
  const handleLoginClick = () => {
    logger.info('login.');
    window.location.assign(Config.AUTHENTICATION_URL_LOGIN);
  };
  const handleLogoutClick = () => {
    logger.info('logout.');
    clearAccessToken();
    window.location.assign(Config.AUTHENTICATION_URL_LOGOUT);
  };

  return (
    <div className={classes.header}>
      <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
        <Typography variant="titleXl">Netprep</Typography>
      </Link>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        {resultAuth.authenticated ? (
          <>
            <div style={{ marginRight: 8 }}>
              <Button variant="fill" onClick={handleCreateRecipeClick}>
                <Typography variant="body">Create Recipe</Typography>
              </Button>
            </div>

            {/* Unauthenticated */}
            <Button variant="outline" onClick={handleLogoutClick}>
              <Typography variant="body">Logout</Typography>
            </Button>
          </>
        ) : (
          <>
            {/* Authenticated */}
            <Button variant="fill" onClick={handleLoginClick}>
              <Typography variant="body">Login</Typography>
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
