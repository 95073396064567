import {
  GetRecipeRequest,
  GetRecipeResponse,
  ListSearchRecipeRequest,
  ListSearchRecipeResponse,
} from '@netprep/contract';
import qs from 'query-string';
import { Config } from '../../config';
import { getAuthorization, validateAuthorizedResponse } from '../auth';

/*
 * Get a specific recipe.
 */
export const getRecipe = async ({
  course,
  id,
}: GetRecipeRequest): Promise<{ status: number; data: GetRecipeResponse }> => {
  const response = await fetch(
    `${Config.API_GATEWAY_URL}/query/getRecipe?${qs.stringify({ course, id })}`,
    {
      headers: {
        Authorize: getAuthorization(),
      },
    },
  );

  return {
    status: response.status,
    data: await response.json(),
  };
};

/*
 * List search for recipes.
 */
export const listSearchRecipe = async ({
  pageSize,
  filterCourse,
  cursor,
}: ListSearchRecipeRequest): Promise<{
  status: number;
  data: ListSearchRecipeResponse;
}> => {
  const response = await fetch(
    `${Config.API_GATEWAY_URL}/query/listSearchRecipe?${qs.stringify({
      pageSize,
      filterCourse,
      cursor,
    })}`,
    {
      headers: {
        Authorize: getAuthorization(),
      },
    },
  );

  validateAuthorizedResponse(response);

  const data: ListSearchRecipeResponse = await response.json();

  return {
    status: response.status,
    data,
  };
};
