import { PaginationInfo, Recipe, RecipeCourseEnum } from '@netprep/contract';
import * as selector from '../selector';
import * as query from '../service/query';
import { useStore } from '../store';
import { AccessorQueryResult } from './types';

const cacheIdListSearchRecipe = ({
  pageSize,
  filterCourse,
  cursor,
}: {
  pageSize: number;
  filterCourse: RecipeCourseEnum;
  cursor?: PaginationInfo['nextCursor'];
}) => {
  return `listSearchRecipe#pageSize#${pageSize}#filterCourse#${filterCourse}#cursor#${cursor}`;
};
const _retrieveListSearchRecipe: Map<
  string,
  Promise<AccessorQueryResult<Recipe[]>>
> = new Map();
export const retrieveListSearchRecipe = ({
  pageSize,
  filterCourse,
  cursor,
}: {
  pageSize: number;
  filterCourse: RecipeCourseEnum;
  cursor?: PaginationInfo['nextCursor'];
}): Promise<AccessorQueryResult<Recipe[]>> => {
  const cacheId = cacheIdListSearchRecipe({ pageSize, filterCourse, cursor });
  const cachePromise = _retrieveListSearchRecipe.get(cacheId);
  if (cachePromise) return cachePromise;

  const promise = (async function getResults(): Promise<
    AccessorQueryResult<Recipe[]>
  > {
    const { storeRequest, storeRecipe, storePaginationInfo } =
      useStore.getState();

    // Get from backend.
    const response = await query.listSearchRecipe({
      pageSize,
      filterCourse,
      cursor,
    });
    const recipes = response.data.recipes;
    const paginationInfo = response.data.paginationInfo;

    // Set cache.
    storeRequest(cacheId);
    storeRecipe(recipes);
    storePaginationInfo(cacheId, paginationInfo);

    return {
      data: recipes,
      fetchMore:
        paginationInfo && paginationInfo.nextCursor
          ? () =>
              retrieveListSearchRecipe({
                pageSize,
                filterCourse,
                cursor: paginationInfo.nextCursor,
              })
          : null,
    };
  })();

  _retrieveListSearchRecipe.set(cacheId, promise);

  return promise;
};

/*
 * Get a list search of a course of recipes.
 */
export const useListSearchRecipe = ({
  pageSize,
  filterCourse,
}: {
  pageSize: number;
  filterCourse: RecipeCourseEnum;
}): AccessorQueryResult<Recipe[]> => {
  const cacheId = cacheIdListSearchRecipe({ pageSize, filterCourse });
  const { cacheRequest, cacheResultData, cacheResultPaginationInfo } = useStore(
    state => ({
      cacheRequest: selector.getRequest(cacheId)(state),
      cacheResultData: selector.getRecipeByCourse(filterCourse)(state),
      cacheResultPaginationInfo: selector.getPaginationInfo(cacheId)(state),
    }),
  );
  if (cacheResultData.length > 0) {
    return {
      data: cacheResultData,
      fetchMore:
        cacheResultPaginationInfo && cacheResultPaginationInfo.nextCursor
          ? () =>
              retrieveListSearchRecipe({
                pageSize,
                filterCourse,
                cursor: cacheResultPaginationInfo.nextCursor,
              })
          : null,
    };
  }
  if (cacheRequest) {
    return {
      data: [],
      fetchMore: null,
    };
  }

  // Tell suspense about promise to retrieve backend data.
  throw retrieveListSearchRecipe({ pageSize, filterCourse });
};
