import { ChangeEventHandler, FunctionComponent, useRef } from 'react';
import Select, {
  ActionMeta,
  GroupBase,
  OptionsOrGroups,
  PropsValue,
} from 'react-select';
import classes from './InputSelect.module.css';

type Props = {
  value?: PropsValue<unknown>;
  placeholder?: string;
  options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined;
  isError?: boolean;
  isDisabled?: boolean;
  onChange?: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
};

export const InputSelect: FunctionComponent<Props> = ({
  value,
  placeholder,
  options,
  isError,
  isDisabled,
  onChange,
}) => {
  return (
    <Select
      options={options}
      value={value}
      isDisabled={isDisabled}
      placeholder={placeholder}
      onChange={onChange}
      className={`${classes.input}`}
      styles={{
        control: base => ({
          ...base,
          boxShadow: 'none',
          '&:focus-within': {
            outline: '2px solid var(--theme-color-3) !important',
          },
          ...(isError
            ? {
                border: '2px solid var(--theme-color-error) !important',
              }
            : {}),
          ...(isDisabled
            ? {
                backgroundColor: 'var(--theme-color-gray-1) !important',
              }
            : {}),
        }),
        option: (base, state) => ({
          ...base,
          color: state.isSelected ? 'var(--theme-color-gray-6)' : '',
          backgroundColor: state.isFocused
            ? 'var(--theme-color-3)'
            : state.isSelected
            ? 'var(--theme-color-gray-3)'
            : 'var(--theme-color-gray-1)',
        }),
      }}
    />
  );
};
