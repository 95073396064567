import { FunctionComponent } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import qs from 'query-string';
import { Typography } from '../../../component/display';
import {
  PageLayoutContent,
  PageLayoutHeader,
} from '../../../component/page_layout';
import { useLocalStorage } from '../../../hook';
import {
  FormData,
  formDataDefault,
  FormDataSubmitted,
  FormRecipeLayout,
} from '../../../layout/form_recipe';
import { captureException } from '../../../sentry';
import { useAuth } from '../../../service/auth';
import { createRecipe } from '../../../service/command';
import { useStore } from '../../../store';

const formLocalStorage = 'CommandCreateRecipePage';

export const CommandCreateRecipePageLayout: FunctionComponent = () => {
  const history = useHistory();
  const resultAuth = useAuth();
  const storeRecipe = useStore(state => state.storeRecipe);

  // Redirect unauthenticated users.
  if (!resultAuth.authenticated) {
    // TODO: Should redirect to login page with redirect to this page.
    return <Redirect to={`/`} />;
  }

  const [formData, setFormData] = useLocalStorage<FormData>(
    formLocalStorage,
    formDataDefault,
  );

  // Event Handlers
  const handleSubmit = async (formDataSubmitted: FormDataSubmitted) => {
    try {
      // Create the recipe.
      const result = await createRecipe(formDataSubmitted);
      storeRecipe([result.data.recipe]);

      // Reset local storage since recipe is now stored in BE.
      setFormData(formDataDefault);

      // Navigate user to created recipe.
      history.push(
        `/query/get-recipe?${qs.stringify({
          course: result.data.recipe.course,
          id: result.data.recipe.id,
        })}`,
      );
    } catch (e) {
      captureException(e);
      const error = e as undefined | Error;
      toast(
        <>
          <Typography variant="body">
            Unable to create your recipe at this time.
          </Typography>
          <br />
          <Typography variant="body">
            {error?.message || 'Unkown error occurred.'}
          </Typography>
          <br />
          <Typography variant="body">Please try again later.</Typography>
        </>,
      );
    }
  };

  return (
    <>
      <PageLayoutHeader />

      <PageLayoutContent>
        <FormRecipeLayout
          title="Create Recipe"
          submitButtonText="Create"
          formData={formData}
          onFormDataChange={setFormData}
          onSubmit={handleSubmit}
        />
      </PageLayoutContent>
    </>
  );
};
