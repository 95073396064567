import { FunctionComponent } from 'react';
import { PageLayout } from '../../../component/page_layout';
import { CommandCreateRecipePageLayout } from './CommandCreateRecipePageLayout';

export const CommandCreateRecipePage: FunctionComponent = () => {
  return (
    <PageLayout>
      <CommandCreateRecipePageLayout />
    </PageLayout>
  );
};
