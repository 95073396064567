import { Recipe } from '@netprep/contract';
import { FunctionComponent } from 'react';
import { BiTimer } from 'react-icons/bi';
import { Typography } from '../component/display';

type Size = 'large' | 'small';

type Props = {
  recipe: Recipe;
};

export const RootPageRecipeBannerTotalTime: FunctionComponent<Props> = ({
  recipe,
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <BiTimer style={{ fontSize: '1rem' }} />
      <Typography
        variant={'body'}
        style={{ marginLeft: '0.3125rem', whiteSpace: 'nowrap' }}
      >
        {recipe.timeCook + recipe.timePrep}mins
      </Typography>
    </div>
  );
};
