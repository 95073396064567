import * as yup from 'yup';
import { RecipeCourseEnum } from './RecipeCourseEnum';

export const RecipeSchema = yup.object({
  id: yup.string().required(),
  dateCreated: yup.date().defined(),
  title: yup.string().required(),
  course: yup.mixed().oneOf(Object.values(RecipeCourseEnum)).defined(),
  images: yup.array().of(yup.string().defined()).defined(),
  ingredients: yup.string().required(),
  directions: yup.string().required(),
  timePrep: yup.number().defined(),
  timeCook: yup.number().defined(),
  calories: yup.number().defined(),
  servingSize: yup.number().defined(),
  author: yup.string().required(),
});

export type Recipe = yup.InferType<typeof RecipeSchema>;
