import * as yup from 'yup';
import { RecipeCourseEnum } from '../data';

export const ListSearchRecipeRequestSchema = yup.object({
  pageSize: yup.number().defined().min(5).max(200),
  filterCourse: yup.mixed().oneOf(Object.values(RecipeCourseEnum)).defined(),
  cursor: yup.string().optional(),
});

export type ListSearchRecipeRequest = yup.InferType<
  typeof ListSearchRecipeRequestSchema
>;
