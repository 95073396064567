import * as yup from 'yup';
import { RecipeCourseEnum } from '../data';

const EditRecipeRequestSchemaOldRecipeSchema = yup.object({
  id: yup.string().required(),
  course: yup.mixed().oneOf(Object.values(RecipeCourseEnum)).defined(),
});

const EditRecipeResponseNewRecipeSchema = yup.object({
  title: yup.string().defined(),
  course: yup.mixed().oneOf(Object.values(RecipeCourseEnum)).defined(),
  images: yup.array().of(yup.string().defined()).defined(),
  ingredients: yup.string().defined(),
  directions: yup.string().defined(),
  timePrep: yup.number().defined(),
  timeCook: yup.number().defined(),
  calories: yup.number().defined(),
  servingSize: yup.number().defined(),
});

export const EditRecipeRequestSchema = yup.object({
  oldRecipe: EditRecipeRequestSchemaOldRecipeSchema.defined(),
  newRecipe: EditRecipeResponseNewRecipeSchema.defined(),
});

export type EditRecipeRequest = yup.InferType<typeof EditRecipeRequestSchema>;
