import { Recipe, RecipeCourseEnum } from '@netprep/contract';
import * as selector from '../selector';
import * as query from '../service/query';
import { useStore } from '../store';
import { AccessorQueryResult } from './types';

const cacheIdGetRecipe = ({
  course,
  id,
}: {
  course: RecipeCourseEnum;
  id: string;
}) => {
  return `getRecipe#course#${course}#id#${id}`;
};
const _retrieveGetRecipe: Map<
  string,
  Promise<AccessorQueryResult<Recipe>>
> = new Map();
export const retrieveGetRecipe = ({
  course,
  id,
}: {
  course: RecipeCourseEnum;
  id: string;
}): Promise<AccessorQueryResult<Recipe>> => {
  const cacheId = cacheIdGetRecipe({ course, id });
  const cachePromise = _retrieveGetRecipe.get(cacheId);
  if (cachePromise) return cachePromise;

  const promise = (async (): Promise<AccessorQueryResult<Recipe>> => {
    const { storeRequest, storeRecipe } = useStore.getState();

    // Get from backend.
    const response = await query.getRecipe({ course, id });
    const recipe = response.data.recipe;

    // Set cache.
    storeRequest(cacheId);
    storeRecipe([recipe]);

    return { data: recipe };
  })();

  _retrieveGetRecipe.set(cacheId, promise);

  return promise;
};

/*
 * Get a single recipe.
 */
export const useGetRecipe = ({
  course,
  id,
}: {
  course: RecipeCourseEnum;
  id: string;
}): AccessorQueryResult<Recipe> => {
  const cacheId = cacheIdGetRecipe({ course, id });
  const { cacheRequest, cacheResult } = useStore(state => ({
    cacheRequest: selector.getRequest(cacheId)(state),
    cacheResult: selector.getRecipeById(id)(state),
  }));

  if (cacheResult) {
    return { data: cacheResult };
  }

  if (!cacheRequest) {
    // Tell suspense about promise to retrieve backend data.
    throw retrieveGetRecipe({ course, id });
  }

  if (!cacheResult) {
    throw new Error(`Recipe with id '${id}' not found.`);
  }

  return { data: cacheResult };
};
