import { CSSProperties, FunctionComponent } from 'react';
import classes from './Typography.module.css';

type Variant = 'titleXl' | 'titleLg' | 'title' | 'caption' | 'body';

const classNameVariant: { [Property in Variant]: string } = {
  titleXl: classes.titleXl,
  titleLg: classes.titleLg,
  title: classes.title,
  caption: classes.caption,
  body: classes.body,
};

type Props = {
  variant: Variant;
  className?: string;
  style?: CSSProperties;
  noWrap?: boolean;
  children: React.ReactNode;
};

export const Typography: FunctionComponent<Props> = ({
  variant,
  className,
  style,
  noWrap,
  children,
}) => {
  return (
    <span
      className={`${classes.typography} ${classNameVariant[variant]} ${
        className ? className : ''
      }`}
      style={{ whiteSpace: !noWrap ? 'unset' : 'nowrap', ...style }}
    >
      {children}
    </span>
  );
};
