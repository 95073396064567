import { RecipeCourseEnum } from '@netprep/contract';
import { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { PageLayout } from '../../../component/page_layout';
import { CommandEditRecipePageLayout } from './CommandEditRecipePageLayout';

export const CommandEditRecipePage: FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const search = qs.parse(location.search);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const course: RecipeCourseEnum | undefined = (RecipeCourseEnum as any)[
    typeof search.course === 'string' ? search.course : ''
  ];
  const id = typeof search.id === 'string' ? search.id : '';
  if (!course || !id) {
    history.push('/');
    return null;
  }

  return (
    <PageLayout>
      <CommandEditRecipePageLayout course={course} id={id} />
    </PageLayout>
  );
};
