import { Recipe, RecipeCourseEnum } from '@netprep/contract';
import { createLogger } from '@null-studios/logger';
import { keys, toPairs, values } from 'ramda';
import { FunctionComponent, useState } from 'react';
import {
  AccessorQueryResult,
  retrieveListSearchRecipe,
  useListSearchRecipe,
} from '../accessor';
import { Grid, InputSelect, InputText } from '../component/display';
import { useAuth } from '../service/auth';
import { RootPageRecipeSection } from './RootPageRecipeSection';

const logger = createLogger(__filename);

const PAGE_SIZE = 20;

// Prefetch data.
for (const filterCourse of values(RecipeCourseEnum)) {
  logger.info(`Prefetching list search recipes "${filterCourse}".`);
  retrieveListSearchRecipe({
    pageSize: PAGE_SIZE,
    filterCourse,
  });
}

export const RootPageLayout: FunctionComponent = () => {
  const resultListSearch = keys(RecipeCourseEnum).reduce(
    (aggregate, item) => {
      aggregate[item] = useListSearchRecipe({
        pageSize: PAGE_SIZE,
        filterCourse: RecipeCourseEnum[item],
      });
      return aggregate;
    },
    {} as {
      [Property in RecipeCourseEnum]: AccessorQueryResult<Recipe[]>;
    },
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchCategoryValue, setSearchCategoryValue] = useState<{
    value: 'ANY' | RecipeCourseEnum;
    label: string;
  }>({ value: 'ANY', label: 'Any Category' });

  return (
    <Grid container gap={8}>
      <Grid item xs={12}>
        <Grid container gap={8}>
          <Grid item xs={10}>
            <InputText
              placeholder="Search"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            <InputSelect
              value={searchCategoryValue}
              onChange={v => {
                setSearchCategoryValue(
                  v as { value: 'ANY' | RecipeCourseEnum; label: string },
                );
              }}
              options={keys(RecipeCourseEnum).reduce(
                (aggregate, item) => {
                  aggregate.push({
                    value: item,
                    label: item,
                  });
                  return aggregate;
                },
                [{ value: 'ANY', label: 'Any Category' }] as {
                  value: string;
                  label: string;
                }[],
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      {searchValue.length <= 0 ? (
        <Grid item xs={12}>
          {toPairs(resultListSearch).map(([key, result]) => (
            <RootPageRecipeSection
              key={key}
              course={RecipeCourseEnum[key]}
              result={result}
            />
          ))}
        </Grid>
      ) : (
        <Grid item xs={12}>
          Search Results!
        </Grid>
      )}
    </Grid>
  );
};
