import {
  CreateRecipeRequest,
  CreateRecipeResponse,
  DeleteRecipeRequest,
  DeleteRecipeResponse,
  EditRecipeRequest,
  EditRecipeResponse,
} from '@netprep/contract';
import { Config } from '../../config';
import { getAuthorization, validateAuthorizedResponse } from '../auth';

/*
 * Create recipe.
 */
export const createRecipe = async (
  data: CreateRecipeRequest,
): Promise<{
  status: number;
  data: CreateRecipeResponse;
}> => {
  const response = await fetch(
    `${Config.API_GATEWAY_URL}/command/createRecipe`,
    {
      method: 'POST',
      headers: {
        Authorize: getAuthorization(),
      },
      body: JSON.stringify(data),
    },
  );

  validateAuthorizedResponse(response);

  return {
    status: response.status,
    data: await response.json(),
  };
};

/*
 * Edit recipe.
 */
export const editRecipe = async (
  data: EditRecipeRequest,
): Promise<{
  status: number;
  data: EditRecipeResponse;
}> => {
  const response = await fetch(`${Config.API_GATEWAY_URL}/command/editRecipe`, {
    method: 'POST',
    headers: {
      Authorize: getAuthorization(),
    },
    body: JSON.stringify(data),
  });

  validateAuthorizedResponse(response);

  return {
    status: response.status,
    data: await response.json(),
  };
};

/*
 * Delete a recipe.
 */
export const deleteRecipe = async (
  data: DeleteRecipeRequest,
): Promise<{
  status: number;
  data: DeleteRecipeResponse;
}> => {
  const response = await fetch(
    `${Config.API_GATEWAY_URL}/command/deleteRecipe`,
    {
      method: 'POST',
      headers: {
        Authorize: getAuthorization(),
      },
      body: JSON.stringify(data),
    },
  );

  validateAuthorizedResponse(response);

  return {
    status: response.status,
    data: await response.json(),
  };
};
