import { Recipe } from '@netprep/contract';
import { FunctionComponent } from 'react';
import { IoFastFoodOutline } from 'react-icons/io5';
import { Typography } from '../component/display';

type Size = 'large' | 'small';

type Props = {
  recipe: Recipe;
  size?: Size;
};

export const RootPageRecipeBannerTotalCalories: FunctionComponent<Props> = ({
  size,
  recipe,
}) => {
  const pSize = typeof size === 'undefined' ? 'small' : size;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IoFastFoodOutline style={{ fontSize: '1rem' }} />
      <Typography
        variant={'body'}
        style={{ marginLeft: '0.3125rem', whiteSpace: 'nowrap' }}
      >
        {recipe.calories} calories
      </Typography>
    </div>
  );
};
