import { CSSProperties, FunctionComponent } from 'react';
import { Typography } from '../typography';
import classes from './InputLabel.module.css';

type Props = {
  label: string;
  isRequired?: boolean;
  isError?: boolean;
  style?: CSSProperties;
  children: React.ReactNode;
};

export const InputLabel: FunctionComponent<Props> = ({
  label,
  isRequired,
  isError,
  style,
  children,
}) => {
  return (
    <span className={classes.container} style={style}>
      <div style={{ marginLeft: 4, marginBottom: 8 }}>
        <Typography variant="body" noWrap>
          {label}
          {isRequired && (
            <Typography
              variant="body"
              className={`${isError ? classes.requiredError : ''}`}
            >
              *
            </Typography>
          )}
        </Typography>
      </div>
      {children}
    </span>
  );
};
