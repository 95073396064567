import {
  ChangeEventHandler,
  FocusEventHandler,
  FunctionComponent,
} from 'react';
import classes from './InputNumber.module.css';

type Props = {
  value: number;
  isError?: boolean;
  isDisabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
};

export const InputNumber: FunctionComponent<Props> = ({
  value,
  isError,
  isDisabled,
  onChange,
  onFocus,
}) => {
  return (
    <span className={classes.container}>
      <input
        type="number"
        className={`${classes.input} ${!isError ? '' : classes.inputError}`}
        disabled={isDisabled}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
      />
    </span>
  );
};
