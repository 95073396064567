import * as yup from 'yup';
import { RecipeCourseEnum } from '../data';

export const DeleteRecipeResponseSchema = yup.object({
  id: yup.string().required(),
  course: yup.mixed().oneOf(Object.values(RecipeCourseEnum)).defined(),
});

export type DeleteRecipeResponse = yup.InferType<
  typeof DeleteRecipeResponseSchema
>;
