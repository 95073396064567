import { FunctionComponent } from 'react';
import { ScreenSize, useScreenSizeValue } from '../display';
import classes from './PageLayoutContent.module.css';

const classScreenSize: { [Property in ScreenSize]: string } = {
  [ScreenSize.ExtraSmall]: classes.screenSizeXs,
  [ScreenSize.Small]: classes.screenSizeSm,
  [ScreenSize.Medium]: classes.screenSizeMd,
  [ScreenSize.Large]: classes.screenSizeLg,
  [ScreenSize.ExtraLarge]: classes.screenSizeXl,
};

export const PageLayoutContent: FunctionComponent = ({ children }) => {
  const screenSize = useScreenSizeValue();

  return (
    <div className={`${classes.content} ${classScreenSize[screenSize]}`}>
      {children}
    </div>
  );
};
