import {
  ChangeEventHandler,
  ClipboardEventHandler,
  CSSProperties,
  FormEventHandler,
  FunctionComponent,
  KeyboardEventHandler,
  useEffect,
  useRef,
} from 'react';
import classes from './InputTextArea.module.css';

type Props = {
  value: string;
  placeholder: string;
  autoGrow?: boolean;
  rows?: number;
  isError?: boolean;
  isDisabled?: boolean;
  style?: CSSProperties;
  styleInput?: CSSProperties;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onPaste?: ClipboardEventHandler<HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
};

export const InputTextArea: FunctionComponent<Props> = ({
  value,
  placeholder,
  autoGrow,
  rows,
  isError,
  isDisabled,
  style,
  styleInput,
  onChange,
  onPaste,
  onKeyDown,
}) => {
  const refInput = useRef<null | HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!refInput.current || !refInput.current.parentElement) {
      return;
    }

    refInput.current.parentElement.dataset.replicatedValue =
      refInput.current.value;
  }, [value]);

  return (
    <span
      className={`${classes.container} ${
        !autoGrow ? classes.autoGrowOff : classes.autoGrowOn
      }`}
      style={style}
    >
      <textarea
        ref={refInput}
        className={`${classes.input} ${isError ? classes.inputError : ''}`}
        style={styleInput}
        rows={rows}
        value={value}
        disabled={isDisabled}
        placeholder={placeholder}
        onChange={onChange}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
      />
    </span>
  );
};
