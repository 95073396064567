import { createContext, FunctionComponent } from 'react';
import { useLocalStorage } from '../../hook';
import classes from './ThemeProvider.module.css';

export enum ThemeEnum {
  LIGHT_GREEN = 'LIGHT_GREEN',
  LIGHT_BLUE = 'LIGHT_BLUE',
}

export const ThemeProviderContext = createContext<null | { theme: ThemeEnum }>(
  null,
);

export const ThemeProvider: FunctionComponent = ({ children }) => {
  const [theme, setTheme] = useLocalStorage('theme', ThemeEnum.LIGHT_GREEN);

  let classTheme;
  if (theme === ThemeEnum.LIGHT_GREEN) {
    classTheme = classes.themeLightGreen;
  }

  return (
    <ThemeProviderContext.Provider value={{ theme }}>
      <div className={`${classes.theme} ${classTheme}`}>
        <div className={classes.content}>{children}</div>
      </div>
    </ThemeProviderContext.Provider>
  );
};
