import { ChangeEventHandler, FunctionComponent } from 'react';
import classes from './InputText.module.css';

type Props = {
  value?: string;
  placeholder?: string;
  isError?: boolean;
  isDisabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const InputText: FunctionComponent<Props> = ({
  value,
  placeholder,
  isError,
  isDisabled,
  onChange,
}) => {
  return (
    <span className={classes.container}>
      <input
        className={`${classes.input} ${isError ? classes.inputError : ''}`}
        type="text"
        placeholder={placeholder}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
      />
    </span>
  );
};
