import { DeleteRecipeRequest, DeleteRecipeResponse } from '@netprep/contract';
import { deleteRecipe } from '../service/command';
import { useStore } from '../store';
import { AcessorCommandResult } from './types';

/*
 * Delete a single recipe.
 */
export const useDeleteRecipe = (): AcessorCommandResult<
  (data: DeleteRecipeRequest) => Promise<DeleteRecipeResponse>
> => {
  const { clearRecipe } = useStore(store => ({
    clearRecipe: store.clearRecipe,
  }));

  const promise = async (data: DeleteRecipeRequest) => {
    // Write to backend.
    const result = await deleteRecipe(data);
    const resultData = result.data;

    // Set cache.
    clearRecipe([resultData.id]);

    return resultData;
  };

  return [promise];
};
