import * as yup from 'yup';

export const AuthUserInfoResponseSchema = yup.object({
  authenticated: yup.boolean().defined(),
  email: yup.string().optional(),
});

export type AuthUserInfoResponse = yup.InferType<
  typeof AuthUserInfoResponseSchema
>;
