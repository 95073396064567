import { EditRecipeRequest, EditRecipeResponse } from '@netprep/contract';
import { editRecipe } from '../service/command';
import { useStore } from '../store';
import { AcessorCommandResult } from './types';

/*
 * Edit a single recipe.
 */
export const useEditRecipe = (): AcessorCommandResult<
  (data: EditRecipeRequest) => Promise<EditRecipeResponse>
> => {
  const { storeRecipe, clearRecipe, updateRecipe } = useStore(store => ({
    storeRecipe: store.storeRecipe,
    clearRecipe: store.clearRecipe,
    updateRecipe: store.updateRecipe,
  }));

  const promise = async (
    data: EditRecipeRequest,
  ): Promise<EditRecipeResponse> => {
    // Write to backend.
    const result = await editRecipe(data);
    const resultRecipe = result.data.recipe;

    // Set cache.
    if (data.oldRecipe.id !== resultRecipe.id) {
      clearRecipe([data.oldRecipe.id]);
      storeRecipe([result.data.recipe]);
    } else {
      updateRecipe({ id: data.oldRecipe.id, recipe: result.data.recipe });
    }

    return result.data;
  };

  return [promise];
};
