import * as yup from 'yup';
import { PaginationInfoSchema, RecipeSchema } from '../data';

export const ListSearchRecipeResponseSchema = yup.object({
  recipes: yup.array().of(RecipeSchema.defined()).defined(),
  paginationInfo: PaginationInfoSchema.optional(),
});

export type ListSearchRecipeResponse = yup.InferType<
  typeof ListSearchRecipeResponseSchema
>;
