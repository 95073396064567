import * as yup from 'yup';
import { RecipeCourseEnum } from '../data';

export const CreateRecipeRequestSchema = yup.object({
  title: yup.string().defined(),
  course: yup.mixed().oneOf(Object.values(RecipeCourseEnum)).defined(),
  images: yup.array().of(yup.string().defined()).defined(),
  ingredients: yup.string().defined(),
  directions: yup.string().defined(),
  timePrep: yup.number().defined(),
  timeCook: yup.number().defined(),
  calories: yup.number().defined(),
  servingSize: yup.number().defined(),
});

export type CreateRecipeRequest = yup.InferType<
  typeof CreateRecipeRequestSchema
>;
