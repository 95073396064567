import { createLogger } from '@null-studios/logger';
import { FunctionComponent } from 'react';
import { default as useErrorBoundary } from 'use-error-boundary';
import { ThemeProvider } from './component/theme';

const logger = createLogger(__filename);

/*
 * Root providers for the application.
 *
 * Think of this kindof like an IoC container providing base functionality for the rest of the application.
 * This component is used to bootstrap the application and to bootstrap a testing environment for component tests.
 *
 * Sets up the following:
 * - NoOp
 */
export const ApplicationContainer: FunctionComponent = ({ children }) => {
  const { ErrorBoundary, error } = useErrorBoundary();

  // Error State
  if (error) {
    logger.error(error);
    // TODO@nw: Render error page with error message.
    return <>Error...</>;
  }

  // Happy State
  return (
    <ErrorBoundary>
      <ThemeProvider>{children}</ThemeProvider>
    </ErrorBoundary>
  );
};
