import { Recipe, RecipeCourseEnum } from '@netprep/contract';
import { Fragment, FunctionComponent, MouseEvent, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDraggable } from 'react-use-draggable-scroll';
import { Waypoint } from 'react-waypoint';
import qs from 'query-string';
import { AccessorQueryResult } from '../accessor';
import { Typography } from '../component/display';
import { RootPageRecipeBannerTotalCalories } from './RootPageRecipeBannerTotalCalories';
import { RootPageRecipeBannerTotalTime } from './RootPageRecipeBannerTotalTime';
import classes from './RootPageRecipeSection.module.css';

type Props = {
  course: RecipeCourseEnum;
  result: AccessorQueryResult<Recipe[]>;
};

export const RootPageRecipeSection: FunctionComponent<Props> = ({
  course,
  result,
}) => {
  const history = useHistory();
  const refElementContainer =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const refItems = useRef<{ [key: string]: null | HTMLDivElement }>({});
  const { events } = useDraggable(refElementContainer, {
    // TODO: Apply rubber band effect for scroll area.
    applyRubberBandEffect: false,
  });

  // Event Handlers
  const handleClick = (_: MouseEvent<HTMLDivElement>, recipe: Recipe) => {
    history.push(
      `/query/get-recipe?${qs.stringify({
        course: recipe.course,
        id: recipe.id,
      })}`,
    );
  };
  const handleMouseOver = (_: MouseEvent<HTMLDivElement>, recipe: Recipe) => {
    refItems.current[recipe.id]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  return (
    <div>
      <Typography variant="title">{course}</Typography>

      <div {...events} ref={refElementContainer} className={classes.container}>
        {result.data.map((recipe, i) => (
          <Fragment key={recipe.id}>
            <div
              ref={element => (refItems.current[recipe.id] = element)}
              className={`${classes.recipeEntry} ${
                i !== 0 ? '' : classes.recipeEntryFirst
              } ${
                i === 0 || i !== result.data.length - 1
                  ? ''
                  : classes.recipeEntryLast
              }`}
              style={{
                marginRight:
                  i !== result.data.length - 1 ? '1.125rem' : 'unset',
              }}
              onClick={e => handleClick(e, recipe)}
              onMouseOver={e => handleMouseOver(e, recipe)}
            >
              {/* Image */}
              <img
                className={classes.recipeEntryImage}
                draggable="false"
                src={recipe.images[0]}
                width={'100%'}
                height={'100%'}
              />

              {/* Banner */}
              <div className={classes.recipeEntryBanner}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {recipe.title}
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <RootPageRecipeBannerTotalTime recipe={recipe} />

                  <RootPageRecipeBannerTotalCalories recipe={recipe} />
                </div>
              </div>
            </div>

            {/* When halfway through fetch more */}
            {i === Math.floor(result.data.length / 2) && result.fetchMore && (
              <Waypoint
                horizontal
                onEnter={() => {
                  result.fetchMore && result.fetchMore();
                }}
              />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
