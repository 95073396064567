import { createBrowserHistory } from 'history';
import { FunctionComponent } from 'react';
import { Redirect, Route, Router } from 'react-router-dom';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routes: [string, string | React.ComponentType<any>][];
};

/*
 * Application page routing.
 */
export const ApplicationRouter: FunctionComponent<Props> = ({ routes }) => {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      {routes.map(([path, component], i) =>
        typeof component !== 'string' ? (
          <Route key={i} exact path={path} component={component} />
        ) : (
          <Route key={i} exact path={path}>
            <Redirect to={component} />
          </Route>
        ),
      )}
    </Router>
  );
};
