import { PaginationInfo, Recipe, RecipeCourseEnum } from '@netprep/contract';
import { devtools } from 'zustand/middleware';
import produce from 'immer';
import create from 'zustand';

export type State = {
  // State
  request: Map<string, boolean>;
  pagination: Map<string, undefined | PaginationInfo>;
  recipe: Map<string, Recipe>;

  // Actions
  storeRequest: (key: string) => void;
  storePaginationInfo: (
    key: string,
    paginationInfo: undefined | PaginationInfo,
  ) => void;
  storeRecipe: (recipe: Recipe[]) => void;
  clearRecipe: (ids: string[]) => void;
  updateRecipe: (args: { id: string; recipe: Recipe }) => void;
};

export const useStore = create<State>(
  devtools(
    (set): State => ({
      // State
      request: new Map<string, boolean>(),
      pagination: new Map<string, undefined | PaginationInfo>(),
      recipe: new Map<string, Recipe>(),

      // Actions
      storeRequest: (key: string) =>
        set(
          produce<State>(state => {
            state.request.set(key, true);
          }),
        ),
      storePaginationInfo: (
        key: string,
        paginationInfo: undefined | PaginationInfo,
      ) =>
        set(
          produce<State>(state => {
            state.pagination.set(key, paginationInfo);
          }),
        ),
      storeRecipe: (recipe: Recipe[]) =>
        set(
          produce<State>(state => {
            for (const r of recipe) {
              state.recipe.set(r.id, r);
            }
          }),
        ),
      clearRecipe: (ids: string[]) =>
        set(
          produce<State>(state => {
            for (const id of ids) {
              state.recipe.delete(id);
            }
          }),
        ),
      updateRecipe: ({ id, recipe }: { id: string; recipe: Recipe }) =>
        set(
          produce<State>(state => {
            state.recipe.set(id, recipe);
          }),
        ),
    }),
    {
      serialize: {
        options: {
          map: true,
        },
      } as any,
    },
  ),
);
